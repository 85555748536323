import React, { useState } from 'react';
import './home.scss';
import Logo from '../assets/photo_2_2024-08-17_15-14-53-removebg-preview.png';
import ProfilePic from '../assets/images.jpg';
import Lottie from 'react-lottie';
import animationData from '../image/HpPJRqAtjC.json';
import animationReturn from '../image/VGjgwMlzJL.json';
import { useNavigate } from 'react-router-dom';

const HomePage = () => {
  const [activeTab, setActiveTab] = useState('order');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const navigate = useNavigate();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  
  const defaultOptionsReturn = {
    loop: true,
    autoplay: true,
    animationData: animationReturn,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const switchTab = (tabName) => {
    setActiveTab(tabName);
  };

  const handleFormSubmit = (event, type) => {
    event.preventDefault();
    setModalMessage(type === 'order' ? 'Your Order has been placed!' : 'Return Request Submitted!');
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };
const handlereturn = (event) => {
    event.preventDefault();
    navigate("/Return");
  };
  return (
    <div className="home-container">
      <header className="header">
        <div className="logo-container">
          <img className="logo" src={Logo} alt="Logo" />
        </div>
        <nav className="nav-bar">
          <a href="#order-form" className="nav-link">Order</a>
          <a onClick={handlereturn} className="nav-link">Return</a>
          <a href="#footer" className="nav-link">Contact</a>
        </nav>
        <div className="profile-container">
          <div className="profile-image">
            <img src={ProfilePic} alt="Profile" />
          </div>
          <span className="profile-name">Jane Doe</span>
        </div>
      </header>

      <section className="hero">
        <div className="hero-content">
          <h1>Discover Your Next Favorite Product</h1>
          <p>Ordering has never been easier. Check out our offerings!</p>
        </div>
      </section>

    

     <section className='con'>
     <div className="form-container">
        <section
          id="order-form"
          className={`form-section ${activeTab === 'order' ? 'active-slide' : ''}`}
        >
          <div className="form-content">
            <h2>Order Your Product</h2>
            <form onSubmit={(e) => handleFormSubmit(e, 'order')}>
              <div className="form-group">
                <input type="text" placeholder="Company Name" required />
              </div>
              <div className="form-group">
                <input type="text" placeholder="Contact Person" required />
              </div>
              <div className="form-group">
                <input type="email" placeholder="Email" required />
              </div>
              <div className="form-group">
                <input type="tel" placeholder="Phone Number" required />
              </div>
              <div className="form-group">
                <input type="number" placeholder="Quantity" required />
              </div>
              <div className="form-group">
                <textarea placeholder="Order Details" rows="4" required></textarea>
              </div>
              <button type="submit">Submit Order</button>
            </form>
          </div>
        </section>

       
      </div>

     </section>
      {isModalVisible && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-button" onClick={closeModal}>×</span>
            <h2>{modalMessage}</h2>
          </div>
        </div>
      )}

<footer id="footer" className="footer">
        <p>For inquiries, contact us at <a href="mailto:info@jetsondoro.com">info@jetsondoro.com</a></p>
      </footer>
    </div>
  );
};

export default HomePage;
