import Home from "./home/Home";
import { BrowserRouter, Routes, Route } from 'react-router-dom'; 
import Return from "../src/return/Return";
import Login from "./login/Login";
import Registration from "../src/register/Register"


const App = () =>{
    return (
        <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />  {/* Route for Home */}
          <Route path="/Home" element={<Home />} />  {/* Route for Cause */}
          <Route path="/Return" element={<Return />} />  {/* Route for Cause */}
          <Route path="/Registration" element={<Registration />} />  {/* Route for Cause */}
          {/* Add other routes here */}
        </Routes>
      </BrowserRouter>
    )
}
export default App;