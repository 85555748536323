import React, { useState } from 'react';
import './return.scss';
import Logo from '../assets/photo_2_2024-08-17_15-14-53-removebg-preview.png'; // Update the logo path if necessary
import ProfilePic from '../assets/images.jpg'; // Update the profile picture path if necessary
import { useNavigate } from 'react-router-dom';

const ReturnOrderPage = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const navigate = useNavigate();

  const handleFormSubmit = (event) => {
    event.preventDefault();
    setModalMessage('Return Request Submitted!');
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };
const handleHome = (event) => {
    event.preventDefault();
    navigate("/Home");
  };
  return (
    <div className="return-order-container">
      <header className="header">
        <div className="logo-container">
          <img className="logo" src={Logo} alt="Logo" />
        </div>
        <nav className="nav-bar">
          <a onClick={handleHome} className="nav-link">Order</a>
          <a href="#return" className="nav-link">Return</a>
          <a href="#footer" className="nav-link">Contact</a>
        </nav>
        <div className="profile-container">
          <div className="profile-image">
            <img src={ProfilePic} alt="Profile" />
          </div>
          <span className="profile-name">Jane Doe</span>
        </div>
      </header>

      {/* Hero Section */}
      <section className="hero">
        <div className="hero-content">
          <h1>Discover Your Next Favorite Product</h1>
          <p>Ordering has never been easier. Check out our offerings!</p>
        </div>
      </section>

      <section className="return-order-content">
        <h1>Return Your Product</h1>
        <div className="form-container">
          <form onSubmit={handleFormSubmit}>
            <div className="form-group">
              <input type="text" placeholder="Name" required />
            </div>
            <div className="form-group">
              <input type="text" placeholder="Product Name" required />
            </div>
            <div className="form-group">
              <input type="text" placeholder="Email" required />
            </div>
            <div className="form-group">
              <input type="text" placeholder="Reason for Return" required />
            </div>
            <div className="form-group">
              <textarea placeholder="Additional Comments" rows="4"></textarea>
            </div>
            <button type="submit">Submit Return Request</button>
          </form>
        </div>
      </section>

      {isModalVisible && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-button" onClick={closeModal}>×</span>
            <h2>{modalMessage}</h2>
          </div>
        </div>
      )}
       <footer id="footer" className="footer">
        <p>For inquiries, contact us at <a href="mailto:info@jetsondoro.com">info@jetsondoro.com</a></p>
      </footer>
     
    </div>
  );
};

export default ReturnOrderPage;
