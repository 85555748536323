import React, { useState } from 'react';
import './register.scss';
import PhoneInput from 'react-phone-input-2';
import { useNavigate } from 'react-router-dom';
import Logo from '../assets/photo_2_2024-08-17_15-14-53.jpg';

const Registration = () => {
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [profileImage, setProfileImage] = useState(null); // State for profile image
  const navigate = useNavigate();

  const handleRegister = (e) => {
    e.preventDefault();
    console.log("Name:", name);
    console.log("Email:", email);
    console.log("Phone:", phone);
    console.log("Password:", password);
    console.log("Profile Image:", profileImage);
  };

  const handleReturn = () => {
    navigate("/Home"); // Adjust this to your desired route
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setProfileImage(file);
  };

  return (
    <div className='mainsectionreg'>
        <div className="top">
        <div className="wrapper">
          <img
            className="logo"
            src={Logo}
            alt="Logo"
          />
        </div>
      </div>
      <div className="containerreg">
        <form onSubmit={handleRegister}>
          <h4>Register</h4>

          {/* Profile Image Picker */}
          <div className="image-picker-container">
            <div className="image-preview">
              {profileImage ? (
                <img
                  src={URL.createObjectURL(profileImage)}
                  alt="Profile"
                  className="profileImagePreview"
                />
              ) : (
                <div className="image-placeholder">
                  No Image Selected
                </div>
              )}
            </div>
            <button
              type="button"
              className="chooseImageButton"
              onClick={() => document.getElementById('profileImage').click()}
            >
              Choose Image
            </button>
            <input
              id="profileImage"
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              style={{ display: 'none' }}
            />
          </div>

          {/* Name */}
          <input
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />

          {/* Email */}
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />

          {/* Password */}
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />

          {/* Phone Number */}
          <PhoneInput
            country={'us'}
            value={phone}
            onChange={setPhone}
            placeholder="Phone Number"
            required
          />

          {/* Submit Button */}
          <button type="submit" className="registerButton">Register</button>

          {/* Return to Home */}
          
        </form>
      </div>
    </div>
  );
};

export default Registration;
