import React, { useState } from 'react';
import './login.scss';
import Logo from '../assets/photo_2_2024-08-17_15-14-53.jpg'; // Update with the actual path to your logo
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Import eye icons from react-icons
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
 

  const handleHome = (event) => {
    event.preventDefault();
    navigate("/Home");
  };
  const handleregister = (event) => {
    event.preventDefault();
    navigate("/Registration");
  };
  return (
    <div className="login-container">
      <div className="top">
        <div className="wrapper">
          <img className="logo" src={Logo} alt="Logo" />
        </div>
      </div>
      <div className="form-containerL">
        <h2 className="title">Login</h2>
        <form>
          <input type="text" className="input-field" placeholder="Username" />
          <div className="password-container">
            <input
              type={showPassword ? 'text' : 'password'}
              className="input-field"
              placeholder="Password"
            />
            <button type="button" className="toggle-password" onClick={togglePasswordVisibility}>
              {showPassword ? <FaEye /> : <FaEyeSlash />}
            </button>
          </div>
          <button onClick={handleHome} type="submit" className="login-button">Log In</button>
          <p className="footer-text">
          Don't have an account? <a onClick={handleregister} className="link">Sign up</a>
        </p>
        </form>
      </div>
    </div>
  );
};

export default Login;
